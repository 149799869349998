import {useState} from "react";

import "./Header.scss";

import Logo from "./../../resources/pictures/logo/Logo2.webp";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeOpenMenu = () => {
    setIsMenuOpen(current => !current);
  };

  return (
    <header className="header-section light">
      <nav className="header-section__nav">
        <div className="header-section__first_block">
          <a href="https://minsteel.ru/" aria-current="page">
            <img src={Logo} alt="logo"/>
          </a>
        </div>
        <button className="header-section__toggle" onClick={closeOpenMenu}>
          <span className="material-symbols-outlined">menu</span>
        </button>
        <div className={"header-section__second_block" + (isMenuOpen ? " active" : "")}>
          <ul className="header-section__links">
            <li><a href="#info-section" onClick={closeOpenMenu}>О нас</a></li>
            <li><a href="#products-section" onClick={closeOpenMenu}>Продукция</a></li>
            <li><a href="#exhibitions-section" onClick={closeOpenMenu}>Выставки</a></li>
            <li><a href="#partners-section" onClick={closeOpenMenu}>Партнеры</a></li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

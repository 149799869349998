import "./ProductsSection.scss";

import Product from "./product_block/Product";

import Bauxite from "./../../resources/pictures/products/Bauxite.webp";
import Mullcor60 from "./../../resources/pictures/products/Mullcor60.webp";
import Powder from "./../../resources/pictures/products/Powder.jpg";
import Anchor from "./../../resources/pictures/products/Anchor.webp";
import Concrete from "./../../resources/pictures/products/Concrete.webp";
import PEK from "./../../resources/pictures/products/PEK.webp";
import Andalusite from "./../../resources/pictures/products/Andalusite.webp";
import Refractory from "./../../resources/pictures/products/Refractory.webp";

let productsList = [
  {
    name: "Боксит кальценированный",
    description: "(86, 87)",
    image: Bauxite,
  },
  {
    name: "Андалузит",
    description: "(57, 160)",
    image: Andalusite,
  },
  {
    name: "Муллит",
    description: "(60)",
    image: Mullcor60,
  },
  {
    name: "ПЭК",
    image: PEK,
  },
  {
    name: "Диспергирующие присадки",
    description: "(Алл200, Алл300, ADS, ADW, MADS, MADW)",
    image: Powder,
  },
  {
    name: "Цементы и бетоны",
    description: "(для огнеупорной промышленности)",
    image: Concrete,
  },
  {
    name: "Анкерные крепления",
    description: "(металлические и керамические)",
    image: Anchor,
  },
  {
    name: "Огнеупорная продукция",
    image: Refractory,
  },
];

export default function ProductsSection() {
  return (
    <section className="products-section light" id="products-section">
      <h2 className="products-section__title">Мы поставляем</h2>
      <div className="products-section__content">
        {productsList.map((product, index) => {
          return (
            <Product
              key={index}
              name={product.name}
              description={product.description}
              image={product.image}
            />
          );
        })}
      </div>
    </section>
  );
}

import "./InfoSection.scss";

import Profile from "./../../resources/pictures/info/concr-transformed.webp";

export default function InfoSection() {
  return (
    <section className="info-section dark" id="info-section">
      <h2 className="info-section__title">Наша компания</h2>
      <div className="info-section__content">
        <img src={Profile} alt="Concreate background" width="1920px" height="1020px"/>

        <div className="info-section__description">
          <h3>--- О нашей компании ---</h3>
          <p>
            <strong>ООО «МинСтил»</strong> с 2010 года ведет свою деятельность в области поставок на российский рынок широкой номенклатуры иностранного и российского оборудования, сырья и изделий из него.
            Продукция, поставляемая нашей компанией, используется в <strong>металлургии, электроэнергетике, нефтехимии, строительстве</strong> и ряде других смежных отраслей.
            <br/>
            <br/>
            Наша компания готова поставить на Ваше предприятие в указанной комплектации и в срок <strong>золу рисовой шелухи; цементы и бетоны для огнеупорной промышленности; анкерные крепления: металлические и керамические; огнеупорную продукцию.</strong>
            <br/>
            <br/>
            За время, прошедшее с начала работы, наша компания зарекомендовала себя как надежный партнёр, соблюдающий свои обязательства по срокам, качеству и стоимости поставок.
            <br/>
            <br/>
            Репутация ООО «МинСтил» подтверждается увеличением год от года объемов поставок и списка наших клиентов.
            При необходимости мы можем предоставить контактные данные представителей клиентов для подтверждения указанной выше информации.
            <br/>
            <br/>
            <strong>Приглашаем к сотрудничеству.</strong>
          </p>
        </div>
      </div>
    </section>
  );
}

import "./ExhibitionsSection.scss";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Exhibition1 from "./../../resources/pictures/exhebitions/Exhibition_1.webp";
import Exhibition2 from "./../../resources/pictures/exhebitions/Exhibition_2.webp";

export default function ExhibitionsSection() {
  return (
    <section className="exhibitions-section dark" id="exhibitions-section">
      <h2 className="exhibitions-section__title">Участие в выставках и конференциях</h2>
      <Swiper className="exhibitions-section__carousel"
        modules={[Autoplay, Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
              >
        <SwiperSlide><img src={Exhibition1} alt="Выставка 1" width="2000px" height="1500px"/></SwiperSlide>
        <SwiperSlide><img src={Exhibition2} alt="Выставка 2" width="2000px" height="1500px"/></SwiperSlide>
      </Swiper>
      <div>
      </div>
    </section>
  );
}

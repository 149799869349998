import "./HeroSection.scss";
import Profile from "./../../resources/pictures/hero/Hexagon_2.png";

export default function HeroSection() {
  return (
    <section className="hero-section dark" id="hero-section">
      {/* <h1 className="hero-section__title">Вступление</h1> */}
      <div className="hero-section__content" style={{backgroundImage: `url(${Profile})`, backgroundPosition: "center"}}>
        <div className="hero-section__description">
          <h1>МинСтил</h1>
          <p>
            С нами <strong>удобно, быстро и надёжно.</strong>
            <br/>
            <br/>
            Поставки <strong>сырья для огнеупорной <br/>промышленности, запчастей и анкерных систем.</strong>
          </p>
        </div>
      </div>
    </section>
  );
}

import './App.scss';

import InfoSection from "../sections/info/InfoSection";
import ProductsSection from "../sections/products/ProductsSection";
import ExhibitionsSection from "../sections/exhibitions/ExhibitionsSection";
import HeroSection from "../sections/hero/HeroSection";
import PartnersSection from "../sections/partners/PartnersSection";
import Header from "../general_blocks/header_section/Header";
import Footer from "../general_blocks/footer_section/Footer";
// import FeedbackSection from "../sections/feedback/FeedbackSection";

function App() {
  return (
    <div className="App">
      <Header/>
      <HeroSection/>
      <InfoSection/>
      <ProductsSection/>
      <ExhibitionsSection/>
      <PartnersSection/>
      {/* <FeedbackSection/> */}
      <Footer/>
    </div>
  );
}

export default App;

import React from "react";

import "./Product.scss";

export default function Product(props) {
  return (
    <div className="product">
      <div className="product__image" style={{backgroundImage: `url(${props.image})`, backgroundSize: "cover", backgroundPosition: "center"}}/>
      <h3 className="product__name">{props.name}</h3>
      <p className="product__description">{props.description}</p>
    </div>
  );
}

import "./PartnersSection.scss";

import IzometrikaLogo from "./../../resources/pictures/partners/izometrika.png";

let partnersList = [
  {
    name: "ПАО «Северсталь»",
    link: "https://severstal.com/",
    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Severstal_Logo_CYR_Blue_RGB.png/800px-Severstal_Logo_CYR_Blue_RGB.png",
    alt: "Severstal Logo",
    background: "#ffffff",
  },
  {
    name: "ООО «РЕФМЕТ»",
    link: "https://refmet.ru/",
    image: "https://refmet.ru/wp-content/uploads/2023/05/logo.png",
    alt: "Refmet Logo",
    background: "#15354f",
  },
  {
    name: "Холдинг «НК-Теплохиммонтаж»",
    link: "http://thm-holding.ru/",
    image: "https://i1.wp.com/thm-holding.ru/wp-content/uploads/2020/12/logo_txm_1-01.png?fit=884%2C435",
    alt: "THM Logo",
    background: "#dddddd",
  },
  {
    name: "ООО «Ахмадиев Тонапо Инжиниринг»",
    link: "https://www.tonapo.ru/",
    image: "https://www.tonapo.ru/templates/front/assets/img/logo-white.svg",
    alt: "Tonapo Logo",
    background: "#000000",
  },
  {
    name: "ikb&vhi",
    link: "https://www.vhi-gmbh.com/",
    image: "https://www.vhi-gmbh.com/wp-content/uploads/2023/02/logo-1.png",
    alt: "ikb&vhi Logo",
    background: "#ffffff",
  },
  {
    name: "Изометика",
    link: "http://izometica.ru",
    image: IzometrikaLogo,
    alt: "Izometrika Logo",
    background: "#1b2f85",
  },
  {
    name: "Imerys",
    link: "https://www.imerys.com/",
    image: "https://www.imerys.com/themes/custom/imerys/logo.png.webp",
    alt: "IMERYS Logo",
    background: "#ffffff",
  },
];

export default function PartnersSection() {
  return (
    <section className="partners-section light" id="partners-section">
      <h2 className="partners-section__title">Наши партнёры</h2>
      <div className="partners-section__content">
        {partnersList.map((partner, index) => {
          return (
            <a href={partner.link} title={partner.name} key={index} style={{backgroundColor: partner.background}}>
              <img src={partner.image} alt={partner.alt} decoding="async"
                   loading="lazy"/>
            </a>
          );
        })}
      </div>
    </section>
  );
}

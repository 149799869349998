import "./Footer.scss";

import Logo from "./../../resources/pictures/logo/Logo2.webp";

export default function Footer() {
  return (
    <footer className="footer-section dark">
      <div className="footer-section__content">
        <div className="footer-section__content__logo">
          <a href="https://minsteel.ru/">
            <img src={Logo} alt="logo"/>
          </a>
        </div>
        <div className="footer-section__content__phone">
          <a href="tel:+7 (812) 960-75-64">
            <span className="material-symbols-outlined">call</span>
            +7 (812) 960-75-64
          </a>
          <a href="tel:+7 (812) 580-21-71">
            <span className="material-symbols-outlined">call</span>
            +7 (812) 580-21-71
          </a>
        </div>
        <div className="footer-section__content__email">
          <a href="mailto:sales@minsteel.ru">
            <span className="material-symbols-outlined">mail</span>
            sales@minsteel.ru
          </a>
          <a href="mailto:irinaserkova@mail.ru">
            <span className="material-symbols-outlined">mail</span>
            irinaserkova@mail.ru
          </a>
        </div>
        <div className="footer-section__content__address">
          <a href="https://yandex.ru/maps/2/saint-petersburg/house/kantemirovskaya_ulitsa_12/Z0kYdQRhSEYOQFtjfXV5cnVqbQ==/?ll=30.331140%2C59.983999&z=17.07">
            <span className="material-symbols-outlined">location_on</span>
            194100, г. Санкт-Петербург, ул. Кантемировская, д. 12, литер А, помещ. 34Н 35Н
          </a>
        </div>
      </div>
    </footer>
  );
}
